<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height grey lighten-4"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card
              class="pa-5"
              elevation="2"
            >
              <v-card-text class="pt-5">
                <h1 class="display-4 text-lg-center font-weight-black black--text">
                  4
                  <span class="indigo--text">0</span> 3
                </h1>
                <h4 class="headline text-lg-center mt-3 grey--text">{{errorMessage}}</h4>
              </v-card-text>
              <v-card-actions class="pb-5 mb-5">
                <v-spacer />

                <router-link to="/">
                  <v-btn
                    color="primary"
                    class="font-weight-bold mt-5"
                    height="50"
                    width="160"
                    rounded
                  >HOME</v-btn>
                </router-link>

                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "page403",
  props: {
    errorMessage: {
      type: String,
      default: "The page you requested could not be found!"
    },
  },

};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
</style>